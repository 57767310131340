import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"pt-5"},[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"my-4"},[_c(VCard,{staticClass:"white",attrs:{"elevation":"4"}},[_c(VAlert,{staticClass:"text-left text-h5",attrs:{"text":"","prominent":"","type":"info"}},[(_vm.callBack)?_c('span',[_vm._v("Fetching user information. Please wait")]):_c('span',[_vm._v("Redirecting to Google login. Please wait")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }