<template>
    <main class="pt-5">
        <v-container>

                <!--Grid row-->
                <v-row>
                    <v-col class="my-4">
                        <v-card elevation="4" class="white">

                            <v-alert text prominent type="info" class="text-left text-h5">
                                  Access denied. Please contact <a href="mailto:it.bs@scmp.com">it.bs@scmp.com</a> to apply access right. Thanks.
                            </v-alert>
                        </v-card>
                    </v-col>
                </v-row>


        </v-container>
    </main>
</template>

<script>

export default {
    name: 'NoRight',
    components: {
    },
    data() {

        return {


        };
    },
    computed: {

    },
    created(){

    },
    mounted(){

    },
    methods: {

    }
}

</script>
