<template>
    <main class="pt-5">
        <v-container>

                <!--Grid row-->
                <v-row>
                    <v-col class="my-4">
                        <v-card elevation="4" class="white">

                            <v-alert text prominent type="info" class="text-left text-h5">
                                  <span v-if="callBack">Fetching user information. Please wait</span>
                                  <span v-else>Redirecting to Google login. Please wait</span>
                            </v-alert>
                        </v-card>
                    </v-col>
                </v-row>


        </v-container>
    </main>
</template>

<script>
import axios from "axios";

export default {
    name: 'Login',
    components: {
    },
    data() {

        return {
            callBack: false
        };
    },
    computed: {

    },
    created(){
        let vm = this,
            resJSON;

    },
    mounted(){
        let urlParams = new URLSearchParams(window.location.search),
            code = urlParams.get('oauthcallback');

        if( code == '' || code == undefined ){
            window.location.href= process.env.VUE_APP_API_PATH +"/authorize";
        }else{
            this.callBack = true;

            axios
                .get( process.env.VUE_APP_API_PATH +"/getLoginInfo")
                .then(response => {
                    let result = response.data;
                    console.log(result);

                    if( result == 'no_found' ){
                        window.location.href= "/noRight";
                    }else if( result == "credentials_not_exist" ){
                        window.location.href= process.env.VUE_APP_API_PATH +"/authorize";
                    }else{
                        let localStorage = window.localStorage;
                        localStorage.setItem('id_token', result.id_token);
                        localStorage.setItem('user_name', result.user_name);
                        localStorage.setItem('user_email', result.user_email);
                        localStorage.setItem('user_pic', result.user_pic);
                        localStorage.setItem('user_admin', result.user_admin);

                        window.location.href= process.env.VUE_APP_BASE_PATH;
                    }

            });

        }
    },
    methods: {

    }
}

</script>
